import { FC } from 'react'
import Head from 'next/head'
import { CLOUDINARY_URL, APP_STORE_ID } from 'config'

export type TProps = {
  title: string
  description: string
  image?: string
  url: string
  canonical?: string
  disableIndex?: boolean
}

const MetaHead: FC<TProps> = ({
  title,
  description,
  image = `${CLOUDINARY_URL}/share/share-6.png`,
  url,
  canonical,
  disableIndex,
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, interactive-widget=resizes-content"
      />
      <meta name="description" content={description} />
      <meta name="author" content="EmCasa" />
      <meta name="publisher" content="EmCasa" />
      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />
      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {APP_STORE_ID ? (
        <meta name="apple-itunes-app" content={`app-id=${APP_STORE_ID}`} />
      ) : null}
      <link rel="icon" href="/favicon.ico" />
      {canonical && <link rel="canonical" href={canonical} />}
      {disableIndex && <meta name="robots" content="noindex" />}
    </Head>
  )
}

export default MetaHead
