import classNames from 'classnames'
import {
  FC,
  ReactNode,
  createElement,
  LabelHTMLAttributes,
  HTMLAttributes,
} from 'react'
import styles from './Typography.module.scss'

const headings = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
}

const tagsMapping = {
  ...headings,
  p: 'p',
  b: 'b',
  em: 'em',
  small: 'small',
  span: 'span',
  strong: 'strong',
  legend: 'legend',
  q: 'q',
}

export type TTypographyElements = keyof typeof tagsMapping

type THeadingElements = keyof typeof headings

export type TVariant =
  | 'DisplayBig'
  | 'Display'
  | 'DisplaySmall'
  | 'TitleBig'
  | 'Title'
  | 'TitleSmall'
  | 'ParagraphBig'
  | 'Paragraph'
  | 'ParagraphSmall'
  | 'Label'
  | 'LabelSmall'

type TConditionalProps =
  | ({ as?: 'label' } & LabelHTMLAttributes<HTMLLabelElement>)
  | ({ as?: THeadingElements } & HTMLAttributes<HTMLHeadingElement>)
  | { as?: TTypographyElements }

export type TProps = {
  variant?: TVariant
  bold?: boolean
  noWrap?: boolean
  children?: ReactNode
  className?: string
} & TConditionalProps

const Typography: FC<TProps> = ({
  variant = 'Paragraph',
  as = 'p',
  bold = false,
  noWrap = false,
  children,
  className = '',
  ...props
}): JSX.Element => {
  const Component = createElement(
    as,
    {
      className: classNames(styles[`ecTypography${variant}`], className, {
        [styles.ecTypographyRegular]: !bold,
        [styles.ecTypographyBold]: bold,
        [styles.ecTypographyNoWrap]: noWrap,
      }),
      ...props,
    },
    children,
  )
  return Component
}

export default Typography
