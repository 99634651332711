import { FC, useState } from 'react'
import NextImage, { ImageProps } from 'next/image'
import classNames from 'classnames'
import Loader from 'components/Loader'
import styles from './Image.module.scss'

export type TProps = {
  borderRadius?: 'small' | 'medium' | 'big'
  hasPlaceholder?: boolean
  hasLoaderSpinner?: boolean
  motionFadeIn?: boolean
  responsive?: boolean
} & ImageProps

const Image: FC<TProps> = ({
  borderRadius,
  hasPlaceholder = false,
  hasLoaderSpinner = true,
  motionFadeIn,
  responsive = true,
  ...rest
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  /* istanbul ignore next */
  const handleOnLoadingComplete = (img: HTMLImageElement) => {
    if (rest.onLoadingComplete) rest.onLoadingComplete(img)
    setIsImageLoaded(true)
  }

  const borderRadiusMap = {
    small: {
      className: styles.ecImageBorderRadiusSmall,
    },
    medium: {
      className: styles.ecImageBorderRadiusMedium,
    },
    big: {
      className: styles.ecImageBorderRadiusBig,
    },
  }

  const loading = !isImageLoaded && hasLoaderSpinner

  return (
    <div
      data-testid="ecImage"
      className={classNames(
        styles.ecImage,
        borderRadius && borderRadiusMap[borderRadius].className,
        {
          [styles.ecImagePlaceholder]: hasPlaceholder,
        },
      )}
    >
      {loading && (
        <div
          data-testid="ecImageLoaderPlaceholder"
          className={styles.ecImageLoaderPlaceholder}
        >
          <Loader />
        </div>
      )}
      <NextImage
        {...rest}
        onLoadingComplete={handleOnLoadingComplete}
        unoptimized={(rest.src as string).startsWith('https')}
        className={classNames(rest?.className, {
          [styles.ecImageFadeIn]: motionFadeIn,
          [styles.ecImageFadeInLoaded]: !loading && motionFadeIn,
          [styles.ecImageResponsive]: responsive && rest?.width && rest?.height,
        })}
      />
    </div>
  )
}

export default Image
