export const menuNavigation = [
  {
    href: '/',
    title: 'Home',
    text: 'Conheça como comprar imóvel com a EmCasa',
  },
  {
    href: '/imoveis',
    title: 'Buscar imóvel',
    text: 'Busque imóveis disponíveis para venda',
  },
  {
    href: '/vender',
    title: 'Vender imóvel',
    text: 'Anuncie seu imóvel com fotos profissionais gratuitamente',
  },
  {
    href: '/parceria',
    title: 'Parceria',
    text: 'Tenha acesso a serviços e ferramentas para potencializar seu negócio imobiliário',
  },
  {
    href: '/favoritos',
    title: 'Imóveis favoritos',
    text: 'Veja os seus imóveis favoritos',
  },
  {
    href: '/recomendacoes',
    title: 'Recomendação de imóveis',
    text: 'Veja a nossa seleção diária de imóveis com base no que você procura',
  },
  {
    href: '/avaliar',
    title: 'Avaliar imóvel',
    text: 'Receba uma recomendação de valor de anúncio competitivo para o imóvel ',
  },
]
