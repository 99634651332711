import { FC, PropsWithChildren } from 'react'
import Button, { TProps as TButtonProps } from 'components/Button'
import classNames from 'classnames'
import styles from './Notification.module.scss'

type TButtons = {
  label: string
  props: TButtonProps
}

type TProps = {
  visible?: boolean
  visibleTimeout?: boolean
  desktopAlignment?: 'left' | 'right'
  buttons?: Array<TButtons>
}

const Notification: FC<PropsWithChildren<TProps>> = ({
  children,
  visible = true,
  visibleTimeout,
  desktopAlignment,
  buttons,
}) => {
  const notificationClassName = classNames(styles.ecNotification, {
    [styles.ecNotificationVisible]: visible && !visibleTimeout,
    [styles.ecNotificationvisibleTimeout]: visibleTimeout,
    [styles.ecNotificationLeftAlignment]: desktopAlignment === 'left',
    [styles.ecNotificationRightAlignment]: desktopAlignment === 'right',
  })
  return (
    <div className={notificationClassName}>
      <div className={styles.ecNotificationContent}>{children}</div>
      {buttons && (
        <div className={styles.ecNotificationButtons}>
          {buttons.map((item, index) => {
            const key = `${index}${item.label}`
            return (
              <Button
                key={key}
                {...item.props}
                className={styles.ecNotificationButton}
              >
                {item.label}
              </Button>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Notification
