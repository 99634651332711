import { FC, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import classNames from 'classnames'
import logger from 'lib/logger'
import useEventListener from 'hooks/useEventListener'
import dynamic from 'next/dynamic'
import Button from 'components/Button'
import Logo from './components/Logo'
import { TProps as TMenuItemProps } from 'components/navigation/Menu/MenuItem'
import { menuAtom } from '../Menu/menuAtom'
import { IconStrip3 } from 'icons'
import { THeaderSharedProps } from './types'
import styles from './Header.module.scss'
import { getAppVersion } from 'lib/experiment'

const Menu = dynamic(() => import('../Menu'))

type TProps = {
  fromRight?: boolean
  nav: Array<TMenuItemProps>
} & THeaderSharedProps

const Header: FC<TProps> = ({
  hideMenuButton,
  hideLogo,
  fromRight,
  hideShadow,
  borderBottom,
  nav,
  LeadingElement,
  CenteredElement,
  TrailingElement,
  position,
}) => {
  const [{ isVisible, isMounted }, setMenu] = useAtom(menuAtom)
  const [isScrolled, setIsScrolled] = useState(false)
  const isWebview = !!getAppVersion()

  const toggleNav = () => {
    const eventName = isVisible ? 'header-menu-close' : 'header-menu-open'

    logger.action(eventName)
    setMenu({ isMounted: true, isVisible: !isVisible })
  }

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0)
  }

  useEventListener({
    type: 'scroll',
    listener: handleScroll,
  })

  useEffect(() => {
    setIsScrolled(window.scrollY > 0)
  }, [])

  const ecHeaderClasses = classNames(styles.ecHeader, {
    [styles.ecHeaderFixed]: position === 'fixed',
    [styles.ecHeaderTransparent]: !isScrolled && position === 'fixed',
    [styles.ecHeaderNoShadow]: hideShadow,
    [styles.ecHeaderBorderBottom]: borderBottom,
  })

  return (
    <header className={ecHeaderClasses}>
      <div
        className={classNames(styles.ecHeaderWrapper, {
          [styles.ecHeaderWrapperNoMenuButton]: hideMenuButton || isWebview,
        })}
      >
        <div className={styles.ecHeaderLeading}>
          {LeadingElement && LeadingElement}
          {!hideMenuButton && !isWebview && (
            <Button
              aria-label="Abrir menu"
              theme="white"
              shape="circle"
              onClick={toggleNav}
              Icon={{
                Component: IconStrip3,
                color: 'gray900',
                title: 'Menu',
              }}
            />
          )}
          {!hideLogo && <Logo isScrolled={isScrolled} position={position} />}
        </div>
        <div className={styles.ecHeaderCentered}>{CenteredElement}</div>
        <div className={styles.ecHeaderTrailing}>{TrailingElement}</div>
      </div>
      {isMounted && (
        <Menu
          nav={nav}
          isVisible={isVisible}
          handleCloseButtonClick={toggleNav}
          handleBackgroundClick={toggleNav}
          handleClose={() => setMenu({ isMounted: true, isVisible: false })}
          fromRight={fromRight}
        />
      )}
    </header>
  )
}

export default Header
