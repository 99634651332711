import { atom } from 'jotai'

type TMenuState = {
  isMounted: boolean
  isVisible: boolean
}

export const menuAtom = atom<TMenuState>({
  isMounted: false,
  isVisible: false,
})
